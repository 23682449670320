// extracted by mini-css-extract-plugin
export var about = "InternalLinks-module--about--jtqcp";
export var articles = "InternalLinks-module--articles--c7jtt";
export var contact = "InternalLinks-module--contact--2Bidy";
export var container = "InternalLinks-module--container--jSQEy";
export var content = "InternalLinks-module--content---1uCx";
export var hamburgerContainer = "InternalLinks-module--hamburgerContainer--qTF8Z";
export var home = "InternalLinks-module--home--tA+ap";
export var minidot = "InternalLinks-module--minidot--vH6wF";
export var projects = "InternalLinks-module--projects--6Vrzv";
export var redDot = "InternalLinks-module--redDot--S2grL";